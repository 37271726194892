require('../../default/js/app')

const copyrights = document.querySelectorAll('footer .copyright');
copyrights.forEach(el => {
    const text = el.innerHTML.replace('Rosalia Express', '<span class="text-danger">Rosalia Express</span>');
    el.innerHTML = text
})

document.addEventListener('DOMContentLoaded', function () {

    if ($('#slider-promo').length > 0) {
        new Splide('#slider-promo', {
            type: 'loop',
            focus: 'center',
            autoplay: true,
            perPage: 1,
            autoScroll: {
                speed: 1,
            },
        }).mount();
    }

    if ($('#slider-services').length > 0) {
        new Splide('#slider-services', {
            gap: 10,
            type: 'loop',
            focus: 'left',
            autoplay: true,
            pagination: false,
            perPage: 3,
            autoScroll: {
                speed: 1,
            },
            breakpoints: {
                640: {
                    perPage: 2,
                },
                480: {
                    perPage: 1,
                },
            }
        }).mount();
    }

    if ($('#slider-review').length > 0) {
        new Splide('#slider-review', {
            gap: 10,
            type: 'loop',
            focus: 'left',
            autoplay: true,
            arrows: false,
            perPage: 2,
            autoScroll: {
                speed: 1,
            },
            breakpoints: {
                480: {
                    perPage: 1,
                },
            }
        }).mount();
    }

    function animateNavbar() {
        let height = window.pageYOffset;
        height > 10 ? $('.navbar').removeClass('navbar-transparent') : $('.navbar').addClass('navbar-transparent')
    }

    window.addEventListener('scroll', function () {
        animateNavbar()
    })

    animateNavbar()
});
